<template>
  <div class="setting" :style="{ '--max-height': maxHeight + 'px' }">
    <div class="left">
      <!-- 轮播图 -->
      <div class="setting-item">
        <div class="df-row">
          <span class="df-col title">顶部轮播图</span>

          <div class="df-col">
            <el-button
              type="primary"
              size="mini"
              plain
              @click="onShowPictrue('GalleryImages')"
              >+ 新 增</el-button
            >
          </div>
        </div>

        <div class="swiper-list">
          <div
            class="swiper-item"
            v-for="(item, index) in editData.GalleryImages"
            :key="index"
          >
            <el-image
              :src="item.ImageUrl || defaultImg"
              fit="cover"
              :preview-src-list="[item.ImageUrl || '']"
            />
            <el-input
              v-model="item.LinkUrl"
              placeholder="跳转路径(选填)"
              size="mini"
              clearable
            >
              <el-button slot="append" size="mini" @click="onSearchPath"
                >路径</el-button
              >
            </el-input>

            <i
              class="close el-icon-circle-close"
              @click="editData.GalleryImages.splice(index, 1)"
            ></i>
          </div>
          
          <div class="tips">建议轮播图图片的宽高比为2:1，如970px*485px</div>
        </div>
      </div>

      <!-- 热门分类 -->
      <div class="setting-item">
        <div class="df-row">
          <span class="df-col title">热门商品分类</span>
          <div class="df-col">
            <el-select
              :value="tempCateItem.CategoryId"
              placeholder="选择分类"
              size="mini"
              clearable
              filterable
              @change="onChangeCate"
            >
              <el-option
                v-for="item in categoryList"
                :key="item.CategoryId"
                :label="item.Prefix + item.Name"
                :value="item.CategoryId"
              >
              </el-option>
            </el-select>
          </div>
          <div class="df-col">
            <el-button type="primary" size="mini" plain @click="onAddHotCate"
              >+ 新 增</el-button
            >
          </div>
        </div>

        <div class="hot-cate-list">
          <div
            class="hot-cate-item"
            v-for="(item, index) in editData.HotCategories"
            :key="index"
          >
            <el-image
              :src="item.IconUrl || defaultImg"
              fit="cover"
              @click="onShowPictrue('HotCategories', index)"
            />

            <el-input
              v-model="item.Title"
              placeholder="分类名称(必填)"
              size="mini"
            ></el-input>

            <el-input
              v-model="item.LinkUrl"
              placeholder="跳转路径(选填)"
              size="mini"
              readonly
            ></el-input>

            <i
              class="close el-icon-circle-close"
              @click="editData.HotCategories.splice(index, 1)"
            ></i>
          </div>
        </div>
      </div>

      <!-- 热门拼团商品 -->
      <div class="setting-item">
        <div class="df-row">
          <span class="df-col title">热门拼团商品</span>
          <div class="df-col">
            <el-button
              type="primary"
              size="mini"
              plain
              @click="onShowGroupon('HotGrouponItems')"
              >+ 新 增</el-button
            >
          </div>
        </div>

        <div class="hot-groupon-list">
          <div
            class="hot-groupon-item"
            v-for="(item, index) in editData.HotGrouponItems"
            :key="index"
          >
            <span class="groupon-id">商品ID：{{ item.ItemId }}</span>
            <span class="groupon-name">商品名称：{{ item.ItemName }}</span>
            <i
              class="close el-icon-circle-close"
              @click="editData.HotGrouponItems.splice(index, 1)"
            ></i>
          </div>
        </div>
      </div>

      <!-- 活动专区大图 -->
      <div class="setting-item">
        <div class="df-row">
          <span class="df-col title">活动专区</span>
        </div>

        <div class="activity-list">
          <div class="activity-image">
            <span class="tips-title">活动大图链接</span>

            <div class="image">
              <el-image
                v-if="editData.ActivityImageLinker.ImageUrl"
                :src="editData.ActivityImageLinker.ImageUrl"
                fit="cover"
                :preview-src-list="[editData.ActivityImageLinker.ImageUrl]"
              />
              <el-image v-else :src="defaultImg" fit="cover" />
              <div class="btn-box">
                <el-button
                  type="primary"
                  size="mini"
                  plain
                  @click="onShowPictrue('ActivityImageLinker')"
                  >选择图片</el-button
                >
                <el-button
                  type="info"
                  size="mini"
                  plain
                  @click="editData.ActivityImageLinker.ImageUrl = ''"
                  >清除图片</el-button
                >
              </div>
            </div>

            <el-input
              v-model="editData.ActivityImageLinker.LinkUrl"
              placeholder="跳转路径(选填)"
              size="mini"
              clearable
            >
              <el-button slot="append" size="mini" @click="onSearchPath"
                >路径</el-button
              >
            </el-input>
          </div>

          <div class="activity-text">
            <span class="tips-title">活动右上文字链接</span>

            <div class="image">
              <el-image
                v-if="editData.ActivityTextLinkerA.IconUrl"
                :src="editData.ActivityTextLinkerA.IconUrl"
                fit="cover"
                :preview-src-list="[editData.ActivityTextLinkerA.IconUrl]"
              />
              <el-image v-else :src="defaultImg" fit="cover" />
              <div class="btn-box">
                <el-button
                  type="primary"
                  size="mini"
                  plain
                  @click="onShowPictrue('ActivityTextLinkerA')"
                  >选择图片</el-button
                >
                <el-button
                  type="info"
                  size="mini"
                  plain
                  @click="editData.ActivityTextLinkerA.IconUrl = ''"
                  >清除图片</el-button
                >
              </div>
            </div>

            <el-input
              v-model="editData.ActivityTextLinkerA.Title"
              placeholder="标题(必填)"
              size="mini"
              clearable
            ></el-input>

            <el-input
              v-model="editData.ActivityTextLinkerA.SubTitle"
              placeholder="副标题(选填)"
              size="mini"
              clearable
            ></el-input>

            <el-input
              v-model="editData.ActivityTextLinkerA.LinkUrl"
              placeholder="跳转路径(选填)"
              size="mini"
              clearable
            >
              <el-button slot="append" size="mini" @click="onSearchPath"
                >路径</el-button
              >
            </el-input>
          </div>

          <div class="activity-text">
            <span class="tips-title">活动右下文字链接</span>

            <div class="image">
              <el-image
                v-if="editData.ActivityTextLinkerB.IconUrl"
                :src="editData.ActivityTextLinkerB.IconUrl"
                fit="cover"
                :preview-src-list="[editData.ActivityTextLinkerB.IconUrl]"
              />
              <el-image v-else :src="defaultImg" fit="cover" />
              <div class="btn-box">
                <el-button
                  type="primary"
                  size="mini"
                  plain
                  @click="onShowPictrue('ActivityTextLinkerB')"
                  >选择图片</el-button
                >
                <el-button
                  type="info"
                  size="mini"
                  plain
                  @click="editData.ActivityTextLinkerB.IconUrl = ''"
                  >清除图片</el-button
                >
              </div>
            </div>

            <el-input
              v-model="editData.ActivityTextLinkerB.Title"
              placeholder="标题(必填)"
              size="mini"
              clearable
            ></el-input>

            <el-input
              v-model="editData.ActivityTextLinkerB.SubTitle"
              placeholder="副标题(选填)"
              size="mini"
              clearable
            ></el-input>

            <el-input
              v-model="editData.ActivityTextLinkerB.LinkUrl"
              placeholder="跳转路径(选填)"
              size="mini"
              clearable
            >
              <el-button slot="append" size="mini" @click="onSearchPath"
                >路径</el-button
              >
            </el-input>
          </div>
        </div>
      </div>

      <!-- 热门普通商品 -->
      <div class="setting-item">
        <div class="df-row">
          <span class="df-col title">热门普通商品</span>
          <div class="df-col">
            <el-button
              type="primary"
              size="mini"
              plain
              @click="onShowGroupon('HotNormalItems')"
              >+ 新 增</el-button
            >
          </div>
        </div>

        <div class="hot-groupon-list">
          <div
            class="hot-groupon-item"
            v-for="(item, index) in editData.HotNormalItems"
            :key="index"
          >
            <span class="groupon-id">商品ID：{{ item.ItemId }}</span>
            <span class="groupon-name">商品名称：{{ item.ItemName }}</span>
            <i
              class="close el-icon-circle-close"
              @click="editData.HotNormalItems.splice(index, 1)"
            ></i>
          </div>
        </div>
      </div>

      <div class="save-btn">
        <el-button type="info" size="small" @click="onInitHomeData">初始化</el-button>
        <el-button type="primary" size="small" @click="saveHomeSetting"
          >保 存</el-button
        >
      </div>
    </div>

    <!-- 预览视窗 -->
    <!-- <div class="main">
      <div class="preview-box">
        <div
          class="navbar-box"
          :style="[
            {
              '--headColor': 'black',
              '--headBackgroundColor': 'white',
            },
          ]"
          @click="onSelectNavbar"
        >
          <div class="bangs-bar">
            <div class="task-bar">
              ●●●●●●
            </div>

            <span>下午 4:55</span>

            <div class="electricity">
              <span class="dfWebIcon dfWebIcon-jurassic_clock"></span>
              <span class="dfWebIcon dfWebIcon-dianliang"></span>
            </div>
          </div>

          <div class="title-bar">
            <span></span>
            <span class="title">商城</span>
            <div class="more">
              ●●●
            </div>
          </div>
        </div>

        <div class="shopping-home">
            <div class=""></div>
        </div>
        
      </div>
    </div> -->

    <!-- 图库对话框 -->
    <DFGalleryPictrue
      ref="DFGalleryPictrue"
      @onComfirm="onSaveMedia"
    ></DFGalleryPictrue>

    <!-- 功能页路径对话框 -->
    <DfPathTipsDialog ref="DfPathTipsDialog"></DfPathTipsDialog>

    <!-- 拼团商品对话框 -->
    <DfShopGoodsDialog
      ref="DfShopGoodsDialog"
      :multiple="true"
      :isGroupon="isGroupon"
      @onConfirm="onSelectedGoods"
    ></DfShopGoodsDialog>
  </div>
</template>

<script>
import Shop from "@/api/shop.js";
import notPicture from "@/assets/img/no-pictrue-rectangle.png";

let imgUrl = notPicture;
export default {
  data() {
    return {
      defaultImg: imgUrl,

      isGroupon: -1,
      templateIndex: -1,
      tempCateIndex: -1,
      tempCateItem: {
        CategoryId: "",
        Name: "",
      },
      tempKeyName: "",
      maxHeight: "",

      editData: {
        ActivityImageLinker: {},
        ActivityTextLinkerA: {},
        ActivityTextLinkerB: {},
        GalleryImages: [],
        HotCategories: [],
        HotGrouponItems: [],
        HotNormalItems: [],
      },
      categoryList: [],
      saveDisabled: false,
    };
  },

  created() {
    this.getResize();
  },

  mounted() {
    window.addEventListener("resize", this.getResize);

    this.initHomeSetting();
    this.getCategoryList();
  },

  destroyed() {
    window.removeEventListener("resize", this.getResize);
  },

  methods: {
    // 初始化商城首页设置
    async initHomeSetting() {
      try {
        let { data } = await Shop.initHomeSetting();
        this.editData = {
          ActivityImageLinker: data.ActivityImageLinker || {},
          ActivityTextLinkerA: data.ActivityTextLinkerA || {},
          ActivityTextLinkerB: data.ActivityTextLinkerB || {},
          GalleryImages: data.GalleryImages || [],
          HotCategories: data.HotCategories || [],
          HotGrouponItems: data.HotGrouponItems || [],
          HotNormalItems: data.HotNormalItems || [],
        };
        console.log(this.editData);
      } catch (err) {
        console.log(err);
      }
    },

    // 获取分类列表
    async getCategoryList() {
      try {
        let { data } = await Shop.getCategoryList();
        this.categoryList = data;
      } catch (err) {
        console.log(err);
      }
    },

    // 保存设置
    async saveHomeSetting() {
      this.saveDisabled = true;
      try {
        let { errcode } = await Shop.saveHomeSetting(this.editData);
        if (errcode == 0) {
          this.$message.success("保存成功");
          this.initHomeSetting();
        }
      } catch (err) {
        console.log(err);
      } finally {
        this.saveDisabled = false;
      }
    },

    // 获取浏览器窗口大小
    getResize() {
      let { clientHeight } = this.util.getDocumentElementInfo();
      this.maxHeight = clientHeight - (100 + 40 + 48);
    },

    // 初始化设置数据
    onInitHomeData(){
      this.editData = {
        ActivityImageLinker: {
          ImageUrl: "http://www.difengsoft.com/attachment/DFDiFengRuanJianYS/media/e9df171e-cc35-428f-8bf3-836bf56bc96f.png",
          LinkUrl: ""
        },
        ActivityTextLinkerA: {
          IconUrl: "http://www.difengsoft.com/attachment/DFDiFengRuanJianYS/media/de74fc71-16c0-49ef-9eb6-b9171a8b16b5.png",
          LinkUrl: "pages5/shopping/shopping-list",
          SubTitle: "限量折扣，不容错过，快乐购买吧",
          Title: "品牌商城"
        },
        ActivityTextLinkerB: {
          IconUrl: "http://www.difengsoft.com/attachment/DFDiFengRuanJianYS/media/e9df171e-cc35-428f-8bf3-836bf56bc96f.png",
          LinkUrl: "pages/inte/inte-shopping",
          SubTitle: "积分兑换优质商品，快来兑换吧",
          Title: "积分商城"
        },
        GalleryImages: [
          {
          ImageUrl: "http://www.difengsoft.com/attachment/DFDiFengRuanJianYS/media/daca7b9c-7346-4b56-88b2-5cec130247f8.jpg",
          LinkUrl: ""
          },
          {
            ImageUrl: "http://www.difengsoft.com/attachment/DFDiFengRuanJianYS/media/9627d419-9d6b-4f34-8457-d74581e1d58e.jpg",
            LinkUrl: ""
          }
        ],
        HotCategories: [],
        HotGrouponItems: [],
        HotNormalItems: [],
      }
    },

    // 查看功能页面路径
    onSearchPath() {
      this.$refs.DfPathTipsDialog.onShowDialog();
    },

    // 打卡图片库对话框
    onShowPictrue(event, index = -1) {
      this.tempKeyName = event;
      this.tempCateIndex = index;
      this.$refs.DFGalleryPictrue.onShowDialog();
    },

    // 打开拼团商品对话框
    onShowGroupon(event) {
      this.isGroupon = event == "HotNormalItems" ? -1 : 1;
      this.tempKeyName = event;
      this.$refs.DfShopGoodsDialog.onShowDialog();
    },

    // 保存选中图片
    onSaveMedia(event) {
      // console.log(event);
      let { tempKeyName, tempCateIndex } = this,
        editData = JSON.parse(JSON.stringify(this.editData)),
        params = [];

      if (tempKeyName == "GalleryImages") {
        event.forEach((e) =>
          params.push({ ImageUrl: e.MediaUrl, LinkUrl: "" })
        );
      } else if (tempKeyName == "HotCategories") {
        return (this.editData[tempKeyName][tempCateIndex].IconUrl =
          event[0].MediaUrl);
      } else if (tempKeyName == "ActivityImageLinker") {
        // return (this.editData[tempKeyName].ImageUrl = event[0].MediaUrl);
        editData[tempKeyName].ImageUrl = event[0].MediaUrl;
        this.editData = editData;
        return;
      } else if (
        tempKeyName == "ActivityTextLinkerA" ||
        tempKeyName == "ActivityTextLinkerB"
      ) {
        // return (this.editData[tempKeyName].IconUrl = event[0].MediaUrl);
        editData[tempKeyName].IconUrl = event[0].MediaUrl;
        this.editData = editData;
        return;
      }

      this.editData[tempKeyName] = [...this.editData[tempKeyName], ...params];
      // console.log(this.editData);
    },

    onChangeCate(event) {
      let param = this.categoryList.find((e) => e.CategoryId == event);
      this.tempCateItem = param;
    },

    // 添加热门分类
    onAddHotCate() {
      if (!this.tempCateItem.CategoryId) {
        return this.$message.info("选择要新增的分类");
      }
      this.editData.HotCategories.push({
        IconUrl: "",
        LinkUrl: `pages5/shopping/shopping-list?category_id=${this.tempCateItem.CategoryId}`,
        SubTitle: "",
        Title: this.tempCateItem.Name,
      });
      this.tempCateItem = {};
    },

    // 选中商品
    onSelectedGoods(event) {
      event.selectItems.forEach((e) => {
        this.editData[this.tempKeyName].push({
          ItemId: e.ItemId,
          ItemName: e.ItemName,
        });
      });
    },

    resetData() {},
  },
};
</script>

<style lang="less" scoped>
.setting {
  //   .flex-row();
  //   height: var(--maxHeight);

  //   > div {
  //     flex: 1;
  //     height: 100%;

  //     > div {
  //       .flex-col();
  //       align-items: unset;
  //       width: 60%;
  //       height: 100%;
  //       box-sizing: border-box;
  //       border: 1px solid #ccc;
  //     }
  //   }

  .left {
    .flex-col;
    align-items: unset;
    max-height: var(--max-height);
    overflow-y: auto;
    box-sizing: border-box;

    .setting-item {
      .flex-col;
      align-items: unset;
      margin-bottom: 20px;
      box-sizing: border-box;

      .title {
        .flex-col;
        justify-content: center;
        align-items: unset;
        font-weight: bold;
        font-size: 16px;
        // border-left: 2px solid @primary-color;
      }

      .swiper-list,
      .hot-cate-list,
      .hot-groupon-list,
      .activity-list {
        .flex-row;
        // align-items: unset;
        flex-wrap: wrap;
        // padding: 0 20px;
        box-sizing: border-box;

        .swiper-item,
        .hot-cate-item,
        .hot-groupon-item,
        .activity-image,
        .activity-text {
          .flex-col;
          padding: 5px;
          // margin: 0 2px;
          box-sizing: border-box;
          border: 1px dashed rgba(0, 0, 0, 0);
          position: relative;

          .el-image {
            width: 100px;
            height: 60px;
            margin-bottom: 5px;
          }

          .el-input {
            width: 240px;
            margin: 5px 0;
          }

          .close {
            position: absolute;
            top: -10px;
            right: -10px;
            font-size: 26px;
            color: tomato;
            visibility: hidden;
            background-color: white;
            cursor: pointer;
          }

          &:hover {
            border-color: #aaa;
            border-radius: 5px;
            .close {
              visibility: unset;
            }
          }
        }

        .tips{
          color: #999;
        }
      }

      .hot-cate-list {
        .hot-cate-item {
          .el-image {
            width: 60px;
            border-radius: 50%;
          }
        }
      }

      .hot-groupon-list {
        .hot-groupon-item {
          .el-image {
            cursor: pointer;
          }

          span {
            width: 100%;
            margin: 5px 10px;
          }
        }
      }

      .activity-list {
        align-items: unset;
        .activity-image,
        .activity-text {
          .flex-col;
          // align-items: unset;
          padding-right: 10px;
          border: 0 !important;

          .tips-title {
            padding-bottom: 10px;
            color: #666;
          }

          .image {
            .flex-row;
            justify-content: center;
            align-items: unset;
            width: 100%;
            .el-image {
              width: 60px;
              height: 60px;
              margin-bottom: 5px;
              margin-right: 10px;
            }

            .btn-box {
              .flex-col;
              .el-button {
                margin: 0;
                margin-bottom: 5px;
              }
            }
          }

          .el-input {
            width: 240px;
            margin: 5px 0;
          }
        }
      }
    }

    .save-btn {
      position: fixed;
      bottom: 10px;
      left: 50vw;
    }
  }

  .main {
    .flex-row();
    justify-content: center;
    align-items: unset;
    box-sizing: border-box;

    .preview-box {
      width: 375px; // 根据手机的分辨率设置宽高
      height: 667px;
      border-color: rgba(0, 0, 0, 0.1);
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
      .navbar-box {
        .flex-col();
        width: 100%;
        height: 80px;
        box-sizing: border-box;
        border: 2px dashed rgba(255, 255, 255, 0.01);
        background-color: var(--headBackgroundColor);

        > div {
          .flex-row();
          width: 100%;
          flex: 1;

          > div,
          > span {
            .flex-row();
            justify-content: center;
            flex: 1;
            font-size: 12px;
          }

          .task-bar,
          .more {
            font-size: 14px;
          }
        }

        .bangs-bar {
          .electricity {
            span:first-child {
              font-size: 10px;
              margin-right: 3px;
            }
            span:last-child {
              font-size: 20px;
            }
          }

          > span {
            flex: 2;
            font-weight: bold;
          }
        }

        .task-bar {
          letter-spacing: -2px;
          .title {
            font-size: 16px;
          }
        }

        .title-bar {
          .title {
            flex: 2;
            font-size: 14px;
            color: var(--headColor);
          }
        }
      }

      .shopping-home {
      }

      //   .navbar-box:hover,
      //   .selected {
      //     border-color: #20a0ff;
      //     cursor: pointer;
      //   }
    }
  }
}
</style>
